<template>
  <div class="strategy" ref="strategy">
    <img src="https://cdn.cailu88.com/jingxuanshi/client_strategy_1.png"/>
    <div class="image-block">
      <img src="https://cdn.cailu88.com/jingxuanshi/client_strategy_2.png"/>
      <div>
        <img src="https://cdn.cailu88.com/jingxuanshi/client_strategy_button_1.png" @click="handleButtonClick('1', $event)"/>
      </div>
    </div>
    <div class="image-block">
      <img src="https://cdn.cailu88.com/jingxuanshi/client_strategy_3.png"/>
      <div>
       <img src="https://cdn.cailu88.com/jingxuanshi/client_strategy_button_2.png" @click="handleButtonClick('2', $event)"/>
        <!-- <img src="https://cdn.cailu88.com/jingxuanshi/client_strategy_button_waiting.png" /> -->
      </div>
    </div>
    <div class="image-block">
      <img src="https://cdn.cailu88.com/jingxuanshi/client_strategy_4.png"/>
      <div style="bottom: 30px">
        <img src="https://cdn.cailu88.com/jingxuanshi/client_strategy_button_3.png" @click="handleButtonClick('3', $event)"/>
      </div>
    </div>
    <div class="bottom">
      <div>
        <!-- <img style="width:328px" src="https://cdn.cailu88.com/jingxuanshi/client_strategy_button_6.png" @click="handleButtonClick('4', $event)"/> -->
       <img src="https://cdn.cailu88.com/jingxuanshi/client_strategy_button_4.png" @click="handleButtonClick('4', $event)"/>
       <img src="https://cdn.cailu88.com/jingxuanshi/client_strategy_button_5.png" @click="handleButtonClick('5', $event)"/>
      </div>
    </div>
    <Loading :click="click"></Loading>
  </div>
</template>
<script>
import Vue from 'vue'
import { Button, Toast } from 'vant'
import Loading from '../../components/loading'
import baseUrl from '@/utils/baseUrl'
import clipboard from '@/utils/copy'

Vue.use(Button)
Vue.use(Toast)
const api = require('../../utils/api').api

export default {
  name: 'strategy',
  data () {
    return {
      id: '',
      token: '',
      version: '',
      channel: '',
      advanceUrl: '',
      buttonType: '',
      isSameUrl: false,
      url: '',
      advanceWord: '20150318020002357',
      giftWord: '20150318020002508',
      click: true,
    }
  },
  created () {
    this.token = this.$route.query.token
    this.version = this.$route.query.version
    this.channel = this.$route.query.channel
    this.appClient = this.$route.query.appClient
  },
  mounted () {
    window.scrollTo({
      top: 0
    })
  },
  components: {
    Loading
  },
  methods: {

    //  按钮点击事件
    handleButtonClick (type, event) {
      let that = this
      if (!this.click) {
        return false
      }
      this.click = true
      this.$.ajax({
        url: baseUrl.baseUrl + api.auth,
        type: 'get',
        headers: {
          'token': that.token,
          'version': that.version,
          'channel': that.channel,
          'appClient': that.appClient,
          'Content-Type': 'application/json;charset=UTF-8'
        },
        async: false,
        success: function (res) {
          if (res.code !== 'USER_UN_LOGIN' && res.code) {
            if (res.data.authStatus == 0) {
              that.click = true
              that.$h5AppLink('openTbAuth', {
                authUrl: res.data.authUrl,
                backUrl: `${baseUrl.pageUrl}/strategy`
              })
            } else {
              if (type == 1) {
                that.isSameUrl = that.buttonType == 1 || that.buttonType == 4 ? true : false
                that.id = that.giftWord
              } else if (type == 2) {
                that.isSameUrl = that.buttonType == 2 || that.buttonType == 5 ? true : false
                that.id = that.advanceWord
              } else if (type == 3) {
                that.id = '20150318019998877'
              } else if (type == 4) {
                that.isSameUrl = that.buttonType == 1 || that.buttonType == 4 ? true : false
                that.id = that.giftWord
              } else if (type == 5) {
                that.isSameUrl = that.buttonType == 2 || that.buttonType == 5 ? true : false
                that.id = that.advanceWord
              }
              that.buttonType = type
              if (that.isSameUrl && that.url){
                Toast.success({
                  duration: 500, // 持续展示 toast
                  message: '复制成功'
                })
                return false;
              }
              that.$.ajax({
                url: baseUrl.baseUrl + api.strategyUrl,
                type: 'post',
                headers: {
                  'token': that.token,
                  'version': that.version,
                  'channel': that.channel,
                  'appClient': that.appClient,
                  'Content-Type': 'application/json;charset=UTF-8'
                },
                data: JSON.stringify({
                  shareScene: true,
                  tbActivityMaterialId: that.id,
                  clientType: 2
                }),
                async: false,
                success: function (res_t) {
                  that.click = true
                  Toast.success({
                    duration: 500, // 持续展示 toast
                    message: '复制成功'
                  })
                  that.url = res_t.data.shareContent
                }
              })
            }
          } else {
            that.click = true
            that.isLogin()
            return false
          }
        }
      })
      this.click = true
      clipboard(this.url, event)
    },

    // 判断登陆
    isLogin () {
      let event = 'login'
      let param = {
        backUrl: `${baseUrl.pageUrl}/strategy`
      }
      this.$h5AppLink(event, param)
    },

  }
}
</script>
<style lang="less" scoped>
.strategy {
  position: relative;
  width: 100%;
  min-height: 100vh;
  text-align: center;
  line-height: 0;
  padding-bottom: 60px;

  img {
    width: 100%;
  }

  .image-block {
    position: relative;
    text-align: center;

    div {
      position: absolute;
      bottom: 16px;
      left: 24px;
      img{
        width: 328px;
      }
    }

    .button {
      //position: absolute;
      //left: 0px;
      //bottom: 54px;
      width: 300px;
      height: 50px;
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      color: #FFFFFF;
      background: linear-gradient(90deg, #FF004F 0%, #FF5500 100%);
      box-shadow: 0px 2px 4px 0px rgba(255, 31, 50, 0.6);
      border-radius: 25px;
      border: none;
    }
  }

  .bottom {
    position: fixed;
    bottom: 0px;
    left: 0px;
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 70px;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      img{
        width: 170px;
      }
      img:last-child {
        margin-left: 15px;
      }
    }
  }
}
</style>
